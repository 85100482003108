<template>
<div class="account_box relative-center">
    <h1 class="mb-3">アカウント情報</h1>
    <v-card class="pa-6 mb-6">
        <v-expand-transition>
            <div v-show="!emailChangeForm">
                <div class="mailspan float-left text-center bold">メールアドレス</div>
                <div class="float-left">：{{User.email}}</div>
                <v-btn class="float-right f-blue" text @click="emailChangeForm=true;$refs.emailChangeForm.email=''">変更する</v-btn>
                <div class="clear"></div>
            </div>
        </v-expand-transition>
        <v-expand-transition>
            <EmailChangeForm ref="emailChangeForm" v-show="emailChangeForm" @ParentMethod="emailChangeForm=false" @SnackOk="SnackOk" />
        </v-expand-transition>
    </v-card>

    <v-card class="pa-6 mb-6">
        <v-expand-transition>
            <div v-show="!passwordFormShow">
                <div class="mailspan float-left text-center bold">パスワード</div>
                <div class="float-left">：********</div>
                <v-btn class="float-right f-blue" text @click="passwordFormShow=true">変更する</v-btn>
                <div class="clear"></div>
            </div>
        </v-expand-transition>
        <v-expand-transition>
            <PasswordChangeForm v-show="passwordFormShow" @ParentMethod="passwordFormShow=false" @SnackOk="SnackOk" />
        </v-expand-transition>
    </v-card>

        <v-card class="pa-6 mb-6">
        <v-expand-transition>
            <div v-show="!verifiedFormShow">
                <div class="mailspan float-left text-center bold">二段階認証</div>
                <div class="float-left">：
                    <span v-if="User.email_verified==0">利用しない</span>
                    <span v-else>利用する</span>
                </div>
                <v-btn class="float-right f-blue" text @click="verifiedFormShow=true">変更する</v-btn>
                <div class="clear"></div>
            </div>
        </v-expand-transition>
        <v-expand-transition>
            <VerifiedChangeForm v-show="verifiedFormShow" @ParentMethod="verifiedFormShow=false" @SnackOk="SnackOk" />
        </v-expand-transition>
    </v-card>

    <Snackbar ref="snack" />
</div>
</template>

<script>
import EmailChangeForm from "@/components/user/emailChangeForm.vue"
import PasswordChangeForm from "@/components/user/passwordChangeForm.vue"
import VerifiedChangeForm from "@/components/user/verifiedChangeForm.vue"
import Snackbar from "@/components/snackbar.vue"
export default {
    data() {
        return {
            emailChangeForm: false,
            passwordFormShow: false,
            verifiedFormShow:false,
        }
    },
    components: {
        EmailChangeForm,
        PasswordChangeForm,
        VerifiedChangeForm,
        Snackbar
    },
    computed: {
        User() {
            return this.$store.getters['user/user']
        },
    },
    methods: {
        SnackOk() {
            this.$refs.snack.snack = true
            this.$refs.snack.message = "変更しました"
        }
    }
}
</script>

<style lang="scss" scoped>
@import "@/assets/sass/user/account.scss";
</style>
