import axios from "axios";

export default {
    data() {
        return {
            emailRegisterRules: [],
        };
    },
    methods: {
        async emailCheck() {
            this.emailRegisterRules = [];

            if (!this.email) {
                this.emailRegisterRules = ["入力必須です"];
                return false;
            }

            if (!/.+@.+\..+/.test(this.email)) {
                this.emailRegisterRules = [
                    "メールアドレスの形式が間違っています",
                ];
                return false;
            }
            const data = {
                email: this.email,
            };
            const response = await axios.post("/user/unique", data);
            if (response.data.error) {
                this.emailRegisterRules = [response.data.error];
                return false;
            }
            return true;
        },
    },
    watch: {
        email() {
            this.emailCheck();
        },
    },
};
