<template>
<div :class="{noevent:loading>0}">
    <v-form ref="form">
        <div class="text-center">
            <h4 class="f-gray">二段階認証の変更</h4>
            <p class="f-09 mb-10 mt-3 text-left">
                ワンタイムによる二段階認証を設定します。有効にすると、ログイン時に登録したメールアドレスにワンタイムパスワードを送ります
            </p>
        </div>
        <v-form ref="form">
            <v-text-field v-model="nowPassword" label="パスワード" :rules="nowPasswordRules" outlined maxlength="100" />
        </v-form>
        <v-btn class="float-right blue_btn" dark @click="codeSend()" :class="{noevent:loading}">
            <v-progress-circular v-show="loading==1" size="20" indeterminate color="white"></v-progress-circular>
            <span v-show="loading==0"><span v-if="User.email_verified==0">有効に</span><span v-else>解除</span>する</span>
        </v-btn>
        <v-btn class="float-left " text @click="$emit('ParentMethod')">戻る</v-btn>
        <div class="clear"></div>
    </v-form>
    <v-dialog v-model="dialog" width="380">
        <v-card class="px-4 py-3">
            <div class="text-center">
                <h4 class="f-gray">ワンタイムパスワードの入力</h4>
                <p class="f-09 mb-10 mt-3 text-left">
                    登録したメールアドレスにワンタイムパスワードを送信しました。ワンタイムパスワード入力後、二段階認証が<span v-if="Info.email_verified==0">有効になります</span><span v-else>解除されます</span>
                </p>
            </div>
            <v-text-field v-model="code" label="ワンタイムパスワード" :rules="codeRules" outlined maxlength="6" />
        </v-card>
    </v-dialog>
</div>
</template>

<script>
import Form from "@/mixins/form"

import axios from "axios"

export default {
    mixins: [Form],
    data() {
        return {
            nowPassword: "",
            nowPasswordRules: [],
            dialog: false,
            code: "",
            codeRules: [],
            loading: 0
        }
    },
    computed: {
        User() {
            return this.$store.getters['user/user']
        },
    },
    methods: {
        async codeSend() {
            this.loading = 1
            if (!this.$refs.form.validate()) {
                this.loading = 0
                return false
            }
            const data = {
                "nowPassword": this.nowPassword
            }

            const response = await axios.post("/user/oneTimeCodeSend", data)

            if (response.data.error) {
                this.nowPasswordRules = [response.data.error]
            }
            if (response.data.ok) {
                this.dialog = true
            }
            this.loading = 0
        }
    },
    watch: {
        nowPassword() {
            this.nowPasswordRules = []
        },
        async code(val) {
            if (val.length == 6) {
                const data = {
                    "code": val,
                    "verified": this.User.email_verified == 0 ? 1 : 0
                }
                const response = await axios.post("/user/oneTimePassCheck", data)

                if (response.data.timeout) {
                    this.codeRules = ["30分が過ぎました。再度、やりなおしてください"]
                } else if (response.data.error) {
                    this.codeRules = [response.data.error]
                }

                if (response.data.ok) {
                    await this.$store.dispatch("user/verifyChange", 1);
                    this.dialog = false
                    this.$refs.form.reset()
                    this.$emit("ParentMethod")
                    this.$emit("SnackOk")
                }
            }
        }
    }
}
</script>
